// 选民意见处理
<template>
  <div class="complaint">
    <div class="theme">
      <div>
        姓名：<span>{{ detail.username }}</span> 时间：<span>{{
          detail.addtime
        }}</span>
      </div>
      <div>所在村组：<span>{{detail.area_name}}</span></div>
    </div>

    <div class="content">
      <p class="title">反应主要问题</p>
      <van-field
        class="text-area"
        maxlength="200"
        readonly
        v-model="detail.title"
        autosize
        type="textarea"
        contenteditable="true"
      />
    </div>
    <div class="content">
      <p class="title">建议/现状</p>
      <van-field
        class="text-area"
        readonly
        v-model="detail.content"
        autosize
        type="textarea"
        contenteditable="true"
      />
    </div>
    <div class="advice" v-if="detail.advise">
      <div class="head">代表意见</div>
      <van-field
        class="text-area"
        maxlength="200"
        v-model="detail.advise"
        autosize
        type="textarea"
        contenteditable="true"
      />
    </div>
    <div class="advice" v-else>
      <div class="head">代表意见</div>
      <van-field
        class="text-area"
        maxlength="200"
        show-word-limit
        v-model="adviceValue"
        autosize
        rows="4"
        type="textarea"
        placeholder="请输入代表意见...（限200字）"
        contenteditable="true"
      />
      <van-button class="btn" @click="submit">提交</van-button>
    </div>
  </div>
</template>
<script>
import { getSuggestDetaill,eventPost } from "../api";
import Upload from "@/components/Upload";
export default {
  name: "electorate",
  data() {
    return {
      detail: {},
      adviceValue: "",
    };
  },
  components: {
    Upload,
    // NavTab,
  },
  created() {
    let id = this.$route.params.id;
    this.init(id);
  },
  methods: {
    init(id) {
      let params = {
        id: id,
      };
      getSuggestDetaill(params).then((res) => {
        this.detail = res.data;
      });
    },
    submit() {
      let params = {
        title: this.detail.title,
        content: this.detail.content,
        advise: this.adviceValue,
        token:window.localStorage.getItem('token')
      }
      eventPost(params).then( res=>{
          console.log('反馈',res)
          if (res.code == 0) {
            this.$toast.success("提交成功")
            setTimeout(() => {
              this.$router.replace("/electorateList");
            }, 2000);
          } else {
            this.$toast.success(res.message);
          }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.complaint {
  padding: 0.27rem 0 0;
  padding-bottom: 1rem;
  position: relative;
  min-height: 14.5rem;
  background: url("../../../assets/images/organization/bg.png");
  .theme {
    width: calc(100% - 0.4rem);
    margin: 0 auto;
    border-radius: 0.2rem;
    line-height: 0.8rem;
    height: 1.6rem;
    padding: 0 0.3rem;
    background-color: #fff;
    div {
      font-size: 0.32rem;
      color: #3b93ff;
      span {
        color: #333;
      }
      > span:first-child {
        margin-right: 0.4rem;
      }
    }
  }

  .title {
    margin-bottom: 0.2rem;
    color: #3B93FF;
  }
  .content {
    border-radius: 0.06rem;
    padding: 0.19rem 0.2rem;
    .text-area {
      border-radius: 0.2rem;
      background-color: #f6faff;
      /deep/ textarea {
        color: #999;
      }
    }
  }
  .advice {
    width: 100%;
    min-height: 2rem;
    background-color: #fff;
    border-radius: 0.3rem 0.3rem 0 0;
    margin-top: 1rem;
    padding: 0.3rem;
    .head {
      color: #3b93ff;
      font-size: 0.32rem;
    }
  }
  .btn {
    color: #fff;
    background: #3b93ff;
    width: 6.55rem;
    display: block;
    border-radius: 0.2rem;
    line-height: 0.88rem;
    margin: 1rem auto;
    font-size: 0.38rem;
    letter-spacing: 0.1rem;
  }
}
</style>